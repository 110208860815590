@use "../../../../../scss/" as *;

.listNav {
  font-weight: bold;
  position: absolute;
  width: 100px;
  right: 25px;
  top: 15px;
  .itemNav {
    list-style-type: none;
    position: absolute;
    z-index: 999;
    & > .navChild {
      display: none;
      position: absolute;
      top: 0;
      right: -25px;
      width: fit-content;
      background-color: $white;
      padding: 10px 0;
      border-radius: 12px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .childList {
        padding-left: 0;
        .childItem {
          padding: 15px 30px;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          text-wrap: nowrap;
          cursor: pointer;
          list-style: none;
        }
        .childItem:hover {
          background-color: #cbddff;
        }
      }
    }
  }
  & > .itemNav:hover {
    .navChild {
      display: block;
    }
  }
}

.listNav.Detail {
  position: relative;
  font-weight: bolder;
  font-size: larger;
  width: max-content;
  .itemNav {
    position: relative;
  }
}
