@use "./variables" as *;

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop-width) and (max-height: $desktop-height) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $latop-width) {
    @content;
  }
}

@mixin laptopSmall {
  @media only screen and (max-width: $laptopSmall-width) {
    @content;
  }
}
