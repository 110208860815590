@use "/src/scss" as *;

.comp-name-contain {
	width: 100%;
	display: flex;
	align-items: center;
	& > .comp-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
		font-size: 14px;
		font-weight: 400;
		width: 30%;
		@include desktop {
			width: 50%;
		}
	}
	& > .group-icons {
		flex: 3;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
	}
}
