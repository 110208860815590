$body-bg: #fff;
$primary-color: #24356e;
$txt-color: #000;
$font-size: 40px;
$white: #fff;
$size: 14px;
$black: #222222;
$latop-width: 1536px;
$sizeBtn: 20px;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 30px;
$border-radius-input: 5px;
$mobile-width: 640px;
$tablet-width: 1024px;
$desktop-width: 1600px;
$desktop-height: 1000px;

$font-family: "Montserrat", sans-serif;
