@use "/src/scss" as *;

.body-content-comp {
  padding-top: 95px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  & > .header {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .title {
      span {
        font-size: calc(#{$font-size} - 8px);
        font-weight: 600;
      }
    }

    & > .search-container {
      display: flex;
      align-items: center;
      & > .input-search-container {
        height: fit-content;
        & > .input-cons {
          input {
            height: 44px;
          }
        }
      }
      & > .btn-search-container {
        display: flex;
        width: 137px;
      }
    }
  }
  & > .table-container {
    display: flex;
    flex: 1;
    columns: 2;
    flex-direction: row;
    justify-content: space-between;
    & > .table-1 {
      width: 30%;
      display: flex;
      gap: 20px;
      flex-direction: column;
      & > .table-header-contain-1 {
        & > .table-header-1 {
          min-height: 40px;
          width: 100%;
          tbody {
            tr {
              td:first-child {
                width: 20%;
                text-align: center;
                padding-left: 0;
                border-right: 1px solid white;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              td:last-child {
                border-bottom: none;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
              @include desktop {
                td:first-child {
                  min-width: 92px;
                }
              }
              @include tablet {
                td:first-child {
                  min-width: 92px;
                }
              }
              td {
                padding-left: 30px;
                background-color: $primary-color;
                font-size: calc(#{$font-size} - 22px);
                font-weight: 600;
                color: white;
              }
            }
          }
        }
      }
      & > .table-body {
        overflow-y: auto;
        scrollbar-width: none;
        height: 100%;
        width: 100%;
        tr {
          td {
            font-size: calc(#{$font-size} - 24px);
            height: 40px;
          }
        }
        & > .table-content-1 {
          width: 100%;
          tbody {
            tr {
              td:first-child {
                width: 20%;
                text-align: center;
                border-right: 1px solid #d9d9d9;
              }
              @include desktop {
                td:first-child {
                  min-width: 92px;
                }
                td:nth-child(2) {
                  width: 350px;
                }
              }
              @include tablet {
                td:first-child {
                  width: 93px;
                }
                td:nth-child(2) {
                  width: 350px;
                }
              }
              td {
                font-weight: 400;
                padding: 9.5px 30px;
                border-radius: 50px;
                border-bottom: 1px solid #d9d9d9;
              }
            }
          }
        }
      }
    }
    & > .table-2 {
      width: 68%;
      display: flex;
      gap: 20px;
      flex-direction: column;
      & > .table-header-contain-2 {
        width: 100%;
        & > .table-header-2 {
          min-height: 40px;
          width: 100%;
          tbody {
            tr {
              td:first-child {
                width: 50%;
                font-weight: 600;
                border-right: 1px solid white;
                border-bottom: none;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              td:last-child {
                width: 50%;
                border-bottom: none;
                font-weight: 600;
                border-left: 1px solid white;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
              @include tablet {
                td:first-child {
                  width: 50%;
                }
              }
              td {
                padding-left: 30px;
                background-color: $primary-color;
                font-size: calc(#{$font-size} - 22px);
                color: white;
              }
            }
          }
        }
      }
      & > .table-body {
        overflow-y: scroll;
        scrollbar-width: none;
        width: 100%;
        tr {
          td {
            font-size: calc(#{$font-size} - 24px);
          }
        }
        & > .table-content-2 {
          width: 100%;
          tbody {
            tr {
              td:first-child {
                width: 50%;
                border-right: 1px solid #d9d9d9;
              }
              @include tablet {
                td:first-child {
                  width: 93px;
                }
                td:last-child {
                  width: 350px;
                }
              }
              td {
                padding: 5px 30px;
                height: 44px;
                @include desktop {
                  height: 43.5px;
                }
                border-bottom: 1px solid #d9d9d9;
              }
            }
          }
        }
      }
    }
  }
  & > .btn-container {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .btn-add-comp {
      @include button(44px, $primary-color, #ffffff, 1px, 10px, 500, 17px);
      width: 212px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > .btn-add {
        @include desktop {
          font-size: calc(#{$font-size} - 24px);
        }
        @include tablet {
          font-size: calc(#{$font-size} - 27px);
        }
      }
    }
    & > .btn-add-comp-1 {
      width: 15%;
      display: flex;
      justify-content: center;
      gap: 10px;
      & > .btn-cancel-comp-1 {
        @include button(44px, #d2d2d2, #ffffff, 1px, 10px, 500, 17px);
        & > .btn-add {
          background-color: #d2d2d2;
        }
        @include desktop {
          font-size: calc(#{$font-size} - 26px);
        }
        @include tablet {
          font-size: calc(#{$font-size} - 27px);
        }
      }
      & > .btn-save-comp-1 {
        @include button(44px, $primary-color, #ffffff, 1px, 10px, 500, 17px);
        @include desktop {
          font-size: calc(#{$font-size} - 26px);
        }
        @include tablet {
          font-size: calc(#{$font-size} - 27px);
        }
      }
    }
    & > .btn-edit-comp-contain {
      width: 150px;
      display: flex;
      & > .btn-edit-comp {
        font-size: calc(#{$font-size} - 20px);
        @include button(
          44px,
          #ffffff,
          $primary-color,
          2px solid,
          10px,
          600,
          17px
        );
        &:hover {
          background-color: $primary-color;
          color: #ffffff;
        }
      }
    }
  }
}

.btn-header-cons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-cons-container {
}

.btn-add-cons {
  @include button(44px, $primary-color, #ffffff, 1px, 5px, 500, 17px);
  font-size: calc(#{$font-size} - 24px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-cons {
  input {
    padding: 0 18px;
    height: 44px;
    font-weight: 500;
    border: none;
    font-size: calc(#{$font-size} - 24px);
    &::placeholder {
      position: absolute;
      font-size: calc(#{$font-size} - 24px);
      font-weight: 500;
      color: #d4d3d3;
    }
    &:focus {
      outline: none; // Tắt đường viền của trình duyệt mặc định
      border: none; // Tắt border khi focus
      box-shadow: none; // Tắt shadow nếu có
    }
  }
}

.comp-name-input {
  input {
    outline: none;
    font-weight: 400;
    border: none;
    border-right: none;
    border-radius: $border-radius-input;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: calc(#{$font-size} - 26px);
    &::placeholder {
      font-size: calc(#{$font-size} - 26px);
      font-weight: 400;
      color: #d4d3d3;
    }
  }
}

.color-code-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  & > .color-code {
    font-size: 14px;
    font-weight: 400;
    flex: 1;
  }
  & > .group-icons-1 {
    flex: 5;
    @include desktop {
      width: 120%;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
}
.color-name-contain {
  width: 100%;
  display: flex;
  align-items: center;
  & > .color-name {
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    @include desktop {
      width: 50%;
    }
  }
  & > .group-icons-2 {
    flex: 3;
    @include desktop {
      width: 90%;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
}

.active-delete-selected {
  background-color: #ffdede;
  transition: background-color 0, 5s ease;
}

.active-edit-selected {
  background-color: #e4ffdd;
  transition: background-color 0.5s ease-in-out;
}

.active-row-selected {
  background-color: #ffeacb;
}
