@use "/src/scss" as *;

.body-content-cons {
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
  display: flex;
  padding-top: 100px;
}

.cons-header {
  display: flex;
  padding: 0 10px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  & > .cons-text-contain {
    font-size: calc(#{$font-size} - 8px);
  }
  & > .search-container {
    display: flex;
    & > .input-search-container {
      height: fit-content;
      & > .input-cons {
        display: flex;
        position: relative;
        input {
          border: none;
          border-radius: 5px;
          height: 44px;
          width: 300px;
          &:focus {
            outline: none; // Tắt đường viền của trình duyệt mặc định
            border: none; // Tắt border khi focus
            box-shadow: none; // Tắt shadow nếu có
          }
        }
      }
    }
    & > .btn-search-container {
      width: 137px;
      display: flex;
    }
  }
}

.card-list-container {
  display: flex;
  flex: 7;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  scrollbar-width: none;
  &--start {
    justify-content: flex-start;
  }
}

.cons-button-add {
  width: 212px;
  position: absolute;
  bottom: 20px;
  right: 0;
}
