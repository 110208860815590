@use "/src/scss" as *;

.body-content {
	height: 100%;
	width: 100%;
	flex-direction: column;
	position: relative;
	display: flex;
	padding-top: 100px;
}

.header {
	display: flex;
	padding-top: 10px;
	padding-bottom: 20px;
	align-items: center;
	justify-content: space-between;
	& > .title {
		span {
			font-size: calc(#{$font-size} - 8px);
			font-weight: 600;
		}
	}
	.search-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		& > .input-search-container {
			height: fit-content;
			& > .input-cons {
				display: flex;
				input {
					height: 44.1px;
				}
			}
		}
		& > .btn-search-container {
			display: flex;
			width: 137px;
		}
	}
}

.table-container {
	display: flex;
	position: relative;
	height: 70%;
	@include desktop {
		height: 66.5%;
	}
	width: 100%;
	display: flex;
	flex-direction: column;
}

.btn-container-add-user {
	height: 18%;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
	.btn-add-user {
		width: 212px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.table-body-user {
	overflow-y: scroll;
	scrollbar-width: none;
	width: 100%;
	height: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: #d9d9d9 1px solid;
	tr {
		td:last-child {
			border-right: none;
		}
		td {
			font-size: calc(#{$font-size} - 24px);
			font-weight: 400;
			height: 40px;
		}
	}
}

.table-content {
	width: 100%;
	tbody {
		tr:last-child {
			td {
				border-bottom: none;
			}
		}
		tr {
			td:first-child {
				width: 90px;
				text-align: center;
			}
			td:nth-child(2) {
				width: 400px;
			}
			td:nth-child(3) {
				width: 300px;
			}
			td:nth-child(4) {
				width: 300px;
			}
			@include desktop {
				td:nth-child(2) {
					width: 320px;
				}
				td:nth-child(3) {
					width: 200px;
				}
				td:nth-child(4) {
					width: 200px;
				}
			}
			@include tablet {
				td:first-child {
					width: 93px;
				}
				td:nth-child(2) {
					width: 350px;
				}
				td:nth-child(3) {
					width: 350px;
				}
			}
			td {
				cursor: pointer;
				padding: 10px 30px;
				border-radius: 50px;
				border-right: 1px solid #d9d9d9;
				border-bottom: 1px solid #d9d9d9;
			}
		}
	}
}

.table-header {
	width: 100%;
	margin-bottom: 20px;
	height: 50px;
	@include desktop {
		height: 50px;
	}
	tbody {
		tr {
			width: 100%;
			td:first-child {
				width: 92px;
				text-align: center;
				padding-left: 0;
				border-right: 1px solid white;
				border-bottom: none;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			td:nth-child(2) {
				width: 400px;
			}
			td:nth-child(3) {
				width: 300px;
			}
			td:nth-child(4) {
				width: 300px;
			}
			td:last-child {
				border-bottom: none;
				border-left: 1px solid white;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			@include desktop {
				td:nth-child(2) {
					width: 320px;
				}
				td:nth-child(3) {
					width: 200px;
				}
				td:nth-child(4) {
					width: 200px;
				}
			}
			@include tablet {
				td:first-child {
					width: 93px;
				}
				td:nth-child(2) {
					width: 350px;
				}
				td:nth-child(3) {
					width: 350px;
				}
			}
			td {
				padding-left: 30px;
				background-color: $primary-color;
				font-size: calc(#{$font-size} - 22px);
				color: white;
			}
		}
	}
}

.table-body {
	height: 100%;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: #d9d9d9 1px solid;
	tr:last-child {
		td {
			border-bottom: none;
		}
	}
	tr {
		td {
			font-size: calc(#{$font-size} - 24px);
			height: 40px;
		}
	}
}

.loading-more {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 99.7%;
	height: 88%;
	margin-top: 65px;
	margin-left: 2px;
	border-radius: 10px;
	// background-color: black;
	border: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(255, 255, 255, 0.8);
	@include desktop {
		height: 83%;
		margin-top: 63px;
	}
}

.spinner {
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
