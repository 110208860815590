.contain {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; // Ensure this is set if it's a parent element
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 85%;
  position: relative;
}

.content.solution {
  width: 100%;
  margin-top: 5%;
}
