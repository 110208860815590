@use "/src/scss" as *;

.btn {
	&-logout {
		@include tablet {
			font-size: calc(#{$font-size} - 26px);
		}
		font-size: calc(#{$font-size} - 20px);
		@include button(44px, #ffffff, $primary-color, 2px solid, 5px, 600, 15px);
		&:hover {
			background-color: $primary-color;
			color: #ffffff;
		}
	}
	&-add {
		font-size: calc(#{$font-size} - 20px);
		@include button(44px, $primary-color, #ffffff, 1px, 10px, 500, 17px);
		&:hover {
			background-color: #203b95;
			color: #ffffff;
		}
	}
	&-file {
		@include tablet {
			font-size: calc(#{$font-size} - 26px);
		}
		font-size: calc(#{$font-size} - 20px);
		@include button(44px, #ffffff, $primary-color, 1px solid, 5px, 600, 15px);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		&:hover {
			background-color: $primary-color;
			color: #ffffff;
		}
	}
	font-size: calc(#{$font-size} - 20px);
	@include button(44px, $primary-color, #ffffff, 1px, 40px, 500, 30px);
}
