@use "/src/scss" as *;

.card-container-cons {
  width: 25%;
  padding: 10px;
  @include desktop {
    height: fit-content;
    padding: 5px;
  }

  .card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    .card-text-container {
      transform: translate(0, -40%);
      .card-text {
        font-size: calc(#{$font-size} - 22px);
      }
    }

    .card-image-container {
      width: 100%;
      height: 250px;
      @include desktop {
        height: 200px;
      }

      .ant-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: 1px solid;
        .ant-image-img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
