@use "../../../../../scss/" as *;

.solution {
  width: 100%;
  height: 100%;
  flex: 1;
  @include flex(center, center);
  overflow: hidden;

  &-left {
    @include flex(center, center);
    flex-direction: column;
    flex-basis: 50%;
    height: 95%;
    display: block;
    width: 100%;
    overflow: hidden;
    @include mobile {
      flex-basis: unset;
      @include flex(center, start);
    }
    &-top {
      flex: 1;
      width: 100%;
      background-color: #d9d9d9;
      padding: 30px 47px 20px 47px;
      @include flex(center, start);
      flex-direction: column;
      gap: 20px;
      overflow: hidden;

      @include mobile {
        flex: unset;
        padding: 20px 0;
      }

      .listContai {
        width: 100%;
        @include flex(center, space-between);
        gap: 15px;
        overflow-x: auto;
        padding-bottom: 10px;

        @include mobile {
          overflow-x: scroll;
        }

        .itemContai {
          width: 100%;
          padding: 10px 5px;
          @include flex(center, center);
          gap: 8px;
          background-color: $white;
          border-radius: 8px;
          cursor: pointer;
          opacity: 0.7;

          .round {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }

          .info {
            @include flex(start, center);
            flex-direction: column;
            gap: 1px;
            text-wrap: nowrap;

            .contai {
              font-size: $size;
              font-weight: 400;

              @include mobile {
                font-size: 14px;
                font-weight: 500;
              }
            }

            .brand {
              font-size: 14px;
              font-weight: 500;
              @include flex(center, center);
              gap: 3px;

              @include mobile {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
      }
      .imageHome {
        position: relative;
        flex: 1;
        overflow-y: hidden;
        width: fit-content;
        @include flex(center, center);
        @include mobile {
          flex: unset;
          width: 100%;
          height: 250px;
        }

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        span {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    &-bottom {
      width: 100%;
      @include flex(center, space-between);
      padding: 25px 48px;

      @include mobile {
        padding: 25px 20px;
        flex-direction: column-reverse;
        gap: 16px;
        width: 95%;
      }

      button {
        @include mobile {
          font-size: 14px;
          padding: 10px 20px;
        }
      }

      .box-btn-save {
        @include flex(center, center);
        gap: 16px;

        @include mobile {
          width: 100%;
          @include flex(center, start);
        }

        & > button:nth-child(1) {
          @include mobile {
          }
        }
      }

      & > .box > button:last-child {
        display: none;

        @include mobile {
          display: block;
        }
      }
      .box {
        @include mobile {
          width: 100%;
          @include flex(center, start);
          gap: 16px;
        }
      }
    }
  }
  &-right {
    flex-basis: 50%;
    @include flex(center, center);
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    border-top: 1px solid #d9d9d9;
    @include mobile {
      display: none;
      position: absolute;
      z-index: 2;
      top: 50px;
      // bottom: 0;
      left: 0;
      right: 0;
      // @include flex(center, center);
      background-color: $white;
      overflow-y: scroll;

      &.show {
        @include flex(center, center);
      }
    }

    &-top {
      width: 100%;
      height: 100%;
      padding: 30px 70px 0 54px;
      @include flex(start, start);
      flex-direction: column;
      box-sizing: border-box;
      overflow-y: scroll;

      @include mobile {
        padding: 14px 20px;
        height: 90%;
      }

      .heading {
        font-size: 36px;
        font-weight: 700;
        color: $black;

        @include laptop {
          font-size: 30px;
        }

        @include mobile {
          display: none;
        }
      }

      .selects {
        padding: 17px 0 43px 0;
        @include flex(center, center);
        gap: 50px;
        width: 100%;

        .selects-brand {
          padding: 0;
        }

        &-brand {
          padding: 10px;
          border-bottom: 2px solid #b0b0b0;
          width: 100%;
          @include flex(center, space-between);
          position: relative;
          cursor: pointer;

          & > span:first-child {
            font-size: 20px;
            font-weight: 500;
            color: #525252;

            @include laptop {
              font-size: 18px;
            }
            @include mobile {
              font-size: 14px;
            }
          }

          & > span:last-child {
            font-size: 24px;
            color: #b0b0b0;
            @include flex(center, center);

            @include mobile {
              font-size: 14px;
            }
          }

          & > .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            max-height: 200px;
            overflow: auto;
            background-color: $white;
            border-radius: 7px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            display: none;
            padding-left: 0;

            li {
              list-style: none;
              font-size: 18px;
              font-weight: 500;
              color: #525252;
              padding: 10px;
              cursor: pointer;

              @include mobile {
                font-size: 14px;
              }
            }

            & > li:hover {
              // color: $white;
              background-color: #ccc;
            }
          }

          &:hover {
            .dropdown {
              display: block;
            }
          }
        }

        &-color {
          padding: 1.5%;
          border-bottom: 2px solid #b0b0b0;
          width: 100%;
          display: flex;
          gap: 5%;

          & > span:first-child {
            font-size: 20px;
            color: #b0b0b0;
            @include flex(center, center);

            @include laptop {
              font-size: 20px;
            }
          }

          & > input {
            border: none;
            outline: none;
            font-size: 20px;
            font-weight: 500;
            height: max-content;

            @include laptop {
              font-size: 18px;
            }

            @include mobile {
              font-size: 14px;
            }
          }

          & > input::placeholder {
            color: #b0b0b0;
          }
        }
      }
      .listColor {
        flex: 1;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-content: start;
        column-gap: 10px;
        row-gap: 10px;
        overflow-y: scroll;

        .itemColor {
          // width: calc(100% / 5);
          height: 130px;
          cursor: pointer;
          @include flex(center, center);
          flex-direction: column;
          // gap: 5px;
          // padding-bottom: 17px;
          box-sizing: border-box;
          // border: 3px solid #fff;
          overflow: hidden;

          & > span:first-child {
            color: $black;
            font-size: 20px;
            font-weight: 500;
            visibility: hidden;
          }
          & > span:nth-child(2) {
            color: $black;
            font-size: 12px;
            font-weight: 400;
            visibility: hidden;
          }
        }

        .itemColor.white {
          border: 1px solid #ccc;
        }

        .itemColor:hover {
          & > span {
            visibility: visible;
            @include mobile {
              visibility: hidden;
            }
          }

          // border: 3px solid #ccc;
          box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        }
      }
    }

    &-bottom {
      width: 100%;
      padding: 20px 48px;
      border: 1px solid #ededed;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @include mobile {
        display: none;
      }
    }
  }
}
