@use "/src/scss" as *;

.upload-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border: 1px solid;
   width: 270px;
   height: 150px;
   cursor: pointer;
   background-image: url("../../assets/empty-image.png");
   background-repeat: no-repeat;
   background-size: 115%;
   background-position: center;
   &--have-image {
      background-image: none;
   }
}

.upload-text {
   font-size: calc(#{$font-size} - 25px);
   font-weight: 600;
}
.boxIcon {
   position: relative;
   //    cursor: default;

   & .deleteIconBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      visibility: hidden;
   }
   &:hover .deleteIconBtn {
      visibility: visible;
   }

   .deleteIconBtn {
      background-color: #fff;
      padding: 5px 10px;
      border: 1px solid #000;
      border-radius: 4px;

      .deleteIcon {
         font-size: 17px;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;
      }
   }

   .nameIconBox {
      width: 100%;
      margin-top: 8px;
      cursor: pointer;

      .nameIcon {
         font-size: 16px;
         font-weight: 400;
         width: 100%;
         padding: 4px;
         text-align: center;
      }

      .changeNameIconBox {
         .nameIconInput {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
         }
         .submitBox {
            float: right;
            display: flex;
            gap: 5px;
            margin-top: 10px;

            span {
               width: 28px;
               padding: 3px 0;
               border: 0.5px solid #000;
               border-radius: 3px;
               display: flex;
               justify-content: center;
               align-items: center;
            }
         }
      }
   }
}
