@use "/src/scss" as *;

.container-login {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	background-image: url("/assets/background.png");
	background-size: 1000px;
	background-repeat: no-repeat;
	background-position: center;
}

.form {
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80%;
	height: 100%;
	.header {
		display: flex;
		align-items: center;
		font-weight: 700;
		padding-bottom: 1rem;
	}
	.button-login,
	.input-group {
		display: flex;
		flex-direction: column;
		width: 90%;
		padding-left: 400px;
		padding-right: 400px;
		@include desktop {
			padding-left: 200px;
			padding-right: 200px;
		}
		@include tablet {
			padding-left: 200px;
			padding-right: 200px;
		}
		@include mobile {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
	.button-login {
		margin-top: 30px;
		button {
			font-size: calc(#{$font-size} - 20px);
			@include tablet {
				font-size: calc(#{$font-size} - 24px);
			}
			@include mobile {
				font-size: calc(#{$font-size} - 30px);
			}
		}
	}
	.input-group {
		.input-field {
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;
			span,
			input {
				font-size: calc(#{$font-size} - 20px);
				@include tablet {
					font-size: calc(#{$font-size} - 24px);
				}
				@include mobile {
					font-size: calc(#{$font-size} - 30px);
				}
			}
			span {
				margin-bottom: 10px;
			}
			input {
				padding-left: 24px;
				font-weight: 500;
				border: 1px solid #d9d9d9;
				border-radius: $border-radius-input;
				&::placeholder {
					transform: translate(0, 10%);
				}
			}
		}
	}
}

.error-message {
	color: red;
	font-size: calc(#{$font-size} - 24px);
	transform: translate(0, 100%);
	@include tablet {
		font-size: calc(#{$font-size} - 26px);
	}
	@include mobile {
		font-size: calc(#{$font-size} - 30px);
	}
}
