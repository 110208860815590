@use "/src/scss" as *;

.input-field {
  display: flex;
  flex-direction: column;

  input {
    font-size: calc(#{$font-size} - 24px);
    @include tablet {
      font-size: calc(#{$font-size} - 24px);
    }
    @include mobile {
      font-size: calc(#{$font-size} - 30px);
    }
  }
  span {
    font-family: "Montserrat";
    font-size: calc(#{$font-size} - 20px);
    font-weight: 600;
    margin-bottom: 10px;
  }

  input {
    border: none;
    padding: 24px 18px;
    height: 40px;
    font-weight: 400;
    font-size: calc(#{$font-size} - 24px);
    font-family: "Montserrat";
    width: -webkit-fill-available;

    &::placeholder {
      font-family: "Montserrat";
      position: absolute;
      font-size: calc(#{$font-size} - 24px);
      font-weight: 400;
      color: #d4d3d3;
    }
    &:focus {
      outline: none; // Tắt đường viền của trình duyệt mặc định
      border: none; // Tắt border khi focus
      box-shadow: none; // Tắt shadow nếu có
    }
  }
}

.buttonFilterContainer {
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  position: absolute;
  // align-items: center;
  justify-content: center;
  bottom: 7px;
  right: 10px;
  & > .buttonFilter {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
  }
  & > .iconButtonFilter {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 0;
    border-radius: 4px;
  }
  & > .buttonFilter::after {
    // background-color: red;
    content: "";
    position: absolute;
    top: 29px;
    width: 30px;
    height: 15px;
  }
  &:focus {
    outline: none; // Tắt đường viền của trình duyệt mặc định
    border: none; // Tắt border khi focus
    box-shadow: none; // Tắt shadow nếu có
  }
}

.modalFilterContainer {
  border: 1px solid #b0b0b0;
  position: absolute;
  z-index: 9999;
  background-color: white;
  top: 50px;
  width: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 20px;

  & > .itemSelect {
  }
  & > .itemCheckbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .iconCheckbox {
      cursor: pointer;
    }
  }
  & > .itemButton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    & > .buttonCancel,
    .buttonFilter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 40px;
      border-radius: 8px;
      border: none;
      & > .contentButton {
        font-size: 14px;
        font-weight: 600;
        color: white;
      }
      & > .buttonCancel {
        background-color: "#B0B0B0";
      }
      & > .buttonFilter {
        background-color: "#3B54A5";
      }
    }
    & > .buttonCancel {
      background-color: #b0b0b0;
    }
    & > .buttonFilter {
      background-color: #3b54a5;
    }
  }
  .titleItemSelect,
  .titleItemCheckbox {
    margin: 14px 0 14px 0;
    font-size: calc(#{$font-size} - 20px);
    font-weight: 600;
  }
}

.color-input {
  input {
    outline: none;
    font-weight: 400;
    border: none;
    border-right: none;
    border-radius: $border-radius-input;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: calc(#{$font-size} - 26px);
    &::placeholder {
      font-family: "Montserrat";
      font-size: calc(#{$font-size} - 26px);
      font-weight: 400;
      color: #d4d3d3;
    }
    &:focus {
      outline: none; // Tắt đường viền của trình duyệt mặc định
      border: none; // Tắt border khi focus
      box-shadow: none; // Tắt shadow nếu có
    }
  }
  &--edit {
    z-index: 10;
    background-color: #e4ffdd;
    input {
      &:focus {
        background-color: #e4ffdd;
        outline: none; // Tắt đường viền của trình duyệt mặc định
        border: none; // Tắt border khi focus
        box-shadow: none; // Tắt shadow nếu có
      }

      background-color: #e4ffdd;
      outline: none;
      font-weight: 400;
      border: none;
      border-right: none;
      border-radius: $border-radius-input;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: calc(#{$font-size} - 26px);
      &::placeholder {
        font-family: "Montserrat";
        font-size: calc(#{$font-size} - 26px);
        font-weight: 400;
        color: #d4d3d3;
      }
      &:focus {
        outline: none; // Tắt đường viền của trình duyệt mặc định
        border: none; // Tắt border khi focus
        box-shadow: none; // Tắt shadow nếu có
      }
    }
  }
}
