@use "/src/scss" as *;

.custom-modal {
  overflow: scroll;
  scrollbar-width: none;
  height: 550px;
  width: 100%;
  padding: 30px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.title-modal {
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  &-text {
    font-size: calc(#{$font-size} - 8px);
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
}

.modal-user-name {
  width: 48%;
}
.modal-user-phone {
  width: 48%;
}

.image-upload-container {
  display: flex;
  gap: 40px;
  justify-content: space-around;
}

.upload-title {
  font-size: calc(#{$font-size} - 20px);
}

.modal-cons-name {
  width: 50%;
}
.modal-cons-id {
  width: 45%;
}

.my-select {
  height: 50px;
}

.modal-view-cons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > span {
    font-size: calc(#{$font-size} - 24px);
    font-weight: 600;
  }
}
