@use "/src/scss" as *;

.navbar-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: #fff;
}

.navbar {
  transition: all 0.3s ease-in-out;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutContainer {
  position: relative;
  overflow: visible;
  .modalLogout {
    position: absolute;
    margin-top: 5px;
    top: 100%;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: $white;
    // padding: 10px 0;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & .childList {
      max-width: 100%;
      width: 100%;
      @include tablet {
        display: none;
      }
      @include mobile {
        display: none;
      }
      & .childItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        text-wrap: nowrap;
        list-style: none;
      }
    }
  }
  & .logout {
    font-size: $sizeBtn;
    color: #3b54a5;
    font-weight: 500;
    padding: 15px 40px;
    border: 1px solid #3b54a5;
    border-radius: 44px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    & span {
      margin-left: 5px;
    }
    & .downIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .upIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include mobile {
      width: 100%;
      font-size: 14px;
      padding: 10px;
      margin-top: 14px;
    }

    @include laptop {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
}

.logo {
  width: fit-content;
  cursor: pointer;
}

.tabs {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  .tab {
    display: flex;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
  }
}

.logout {
  justify-content: flex-end;
}

.btn-container {
  display: flex;
  width: 126px;
  @include tablet {
    width: 100px;
  }
  justify-content: center;
  align-items: center;
}
